import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'
import globalApi from '@/request/globalApi/globalApi'

const END_POINT = 'api/product-configuration'

export const fetchProductConfigurationRequest = () => globalApi.get(END_POINT)

export const postProductConfigurationRequest = (urlSuffix, item, message) => globalApi.post(`${END_POINT}/${urlSuffix}`, item, { message })

export const patchProductConfigurationRequest = (urlSuffix, item, message) => globalApi.patch(`${END_POINT}/${urlSuffix}/${item.id}`, { name: item.name, type: item.type }, { message })

export const deleteProductConfigurationRequest = (urlSuffix, item, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${urlSuffix}/${item.id}`, { message }),
)
