<template>
  <b-tabs
    id="parameters"
    :nav-wrapper-class="{'nav-vertical': $store.getters['app/mdAndUp']}"
    :vertical="$store.getters['app/mdAndUp']"
  >
    <b-tab
      v-for="tab in filteredTabs"
      :key="tab.titleId"
      :active="tab.routeName === $route.name"
      :additional-info="tab.additionalInfo"
      :title="tab.title"
      @click="$router.push({ name: tab.routeName })"
    >
      <keep-alive>
        <component
          :is="tab.component"
          v-if="tab.routeName === $route.name"
          :organization-data.sync="organizationData"
        />
      </keep-alive>
    </b-tab>
  </b-tabs>
</template>

<script>
import Clients from './tab/Clients.vue'
import Organizations from './tab/organizations/Organizations.vue'
import Commands from './tab/Commands.vue'
import Products from './tab/products/Products.vue'
import Sellers from './tab/Sellers.vue'
import Tools from './tab/Tools.vue'

export default {
  name: 'Parameters',

  components: {
    Clients,
    Commands,
    Organizations,
    Products,
    Sellers,
    Tools,
  },
  data() {
    return {
      currentTab: null,
      organizationData: null,
      stepIndex: 0,
      tabs: [
        {
          routeName: 'parametersProducts',
          title: this.$t('parameters.products.title'),
          component: 'products',
          show: true,
          additionalInfo: { title: 'products' },
        },
        {
          title: this.$t('parameters.clients'),
          component: 'clients',
          show: false,
          additionalInfo: { title: 'clients' },
        },
        {
          title: this.$t('parameters.sellers'),
          component: 'sellers',
          show: false,
          additionalInfo: { title: 'sellers' },
        },
        {
          routeName: 'parametersOrganizations',
          title: this.$t('parameters.organizations.title'),
          component: 'organizations',
          show: true,
          additionalInfo: { title: 'organizations' },
        },
        {
          title: this.$t('parameters.commands'),
          component: 'commands',
          show: false,
          additionalInfo: { title: 'commands' },
        },
        {
          title: this.$t('parameters.tools'),
          component: 'tools',
          show: false,
          additionalInfo: { title: 'tools' },
        },
      ],
    }
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter(tab => tab.show)
    },
  },
}
</script>

<style lang="scss">
#parameters {
  .nav-link {
    display: block !important;
  }
}
</style>
