<template>
  <div>
    <b-row class="d-flex p-2">
      <b-col cols="12" class="m-auto">
        <h4 class="text-center m-0">
          {{ $t('parameters.organizations.workrole.title') }}
        </h4>
      </b-col>
    </b-row>
    <b-row class="p-2">
      <b-alert variant="warning" class="full-width" show>
        <div class="alert-body">
          <p class="text-center">
            {{ $t('parameters.organizations.workrole.helper') }}
          </p>
          <p class="text-center">
            {{ $t('parameters.organizations.workrole.helper2') }}
          </p>
        </div>
      </b-alert>
    </b-row>

    <!-- TODO: search, pagination -->
    <app-data-table
      ref="refWorkrolesListTable2"
      table-name="workroles-list-table"
      class="mb-3"
      :items="workroles"
      :fields="fields"
      striped
      :top-table="{ showAdd: $can('OPERATOR_PARAMETER_EDIT') }"
      :actions-table="{
        showDuplicate: $can('OPERATOR_PARAMETER_EDIT'),
        showEdit: $can('OPERATOR_PARAMETER_EDIT'),
        showDelete: $can('OPERATOR_PARAMETER_DELETE'),
      }"
      :bottom-table="{ totalCount: workroles.length }"
      @table-add="addWorkrole()"
      @duplicate="duplicateWorkrole($event.item.id)"
      @edit="editWorkrole($event.item.id)"
      @delete="confirmAlert($event.item)"
    >
      <template #head(Workrole)="data">
        <div class="text-md-center ml-md-5 pl-md-5">
          <div class="ml-md-2">{{ data.label }}</div>
        </div>
      </template>

      <template #cell(Workrole)="{ item }">
        <div class="text-md-center ml-md-5 pl-md-5">
          <div class="ml-0 ml-md-2 text-primary" variant="flat-primary" @click="editWorkrole(item.id)">
            {{ item.name | trans }}
          </div>
        </div>
      </template>
    </app-data-table>
  </div>
</template>

<script>
import AppDataTable from '@/components/AppDataTable.vue'
import { fetchOrganizationConfigurationWorkRoleRequest } from '@/request/globalApi/requests/organizationConfigurationRequests'

export default {
  name: 'WorkrolesList',

  components: { AppDataTable },
  props: {
    workroles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [{ key: 'Workrole', label: this.$t('user.workrole') }],
    }
  },
  methods: {
    goToRightsTable(workrole) {
      this.$emit('updateComponent', workrole)
    },
    addWorkrole() {
      const workrole = {
        id: null,
        // TODO: dynamic lang init
        name: { en: null, fr: null },
        rights: [],
        rightsToAdd: [],
        rightsToDelete: [],
      }
      this.goToRightsTable(workrole)
    },
    duplicateWorkrole(workroleId) {
      fetchOrganizationConfigurationWorkRoleRequest(workroleId).then(({ data }) => {
        const payload = {
          ...data,
          id: null,
          rightsToAdd: data.rights,
          rightsToDelete: [],
        }
        this.goToRightsTable(payload)
      })
    },
    editWorkrole(workroleId) {
      fetchOrganizationConfigurationWorkRoleRequest(workroleId).then(({ data }) => {
        this.goToRightsTable({ ...data, rightsToAdd: [], rightsToDelete: [] })
      })
    },
    confirmAlert(workrole) {
      this.$swal({
        title: this.$t('alert.delete_confirmation.title'),
        text: this.$t('alert.delete_confirmation.message'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('alert.delete_confirmation.cancel'),
        confirmButtonText: this.$t('alert.delete_confirmation.approve'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteWorkrole(workrole)
        }
      })
    },
    deleteWorkrole(workrole) {
      this.$emit('removeWorkrole', workrole.id)
    },
  },
}
</script>
