<template>
  <div>
    <workroles-list
      v-if="componentToShow === 'list'"
      :workroles="workroles"
      @refetchWorkroles="fetchWorkroles"
      @removeWorkrole="deleteOrganizationConfigurationWorkRole($event)"
      @updateComponent="updateComponent"
    />
    <template v-else>
      <rights-table
        :grantable-rights="rights"
        :rights="workrole.rights"
        :user-data="workrole"
        :value="componentToShow"
        :workrole="workrole.name"
        @update:workrole="workrole.name = $event"
        @update:rights="workrole = { ...workrole, ...$event }"
      />
      <b-card>
        <div class="d-flex justify-content-end">
          <b-button :block="$store.getters['app/smAndDown']" class="mr-50" variant="outline-secondary" @click="updateComponent()">
            <font-awesome-icon class="mr-50" icon="ban" />
            {{ $tc('action.cancel') }}
          </b-button>

          <b-button :block="$store.getters['app/smAndDown']" class="mt-0" type="submit" @click="onSubmit()">
            <font-awesome-icon class="mr-50" icon="save" />
            {{ $tc('action.save') }}
          </b-button>
        </div>
      </b-card>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { difference, pick } from 'lodash'

import WorkrolesList from '@/views/parameters/tab/organizations/WorkrolesList.vue'
import RightsTable from '@/components/RightsTable.vue'
import {
  deleteOrganizationConfigurationWorkRoleRequest,
  patchOrganizationConfigurationWorkRoleRequest,
  postOrganizationConfigurationWorkRoleRequest,
} from '@/request/globalApi/requests/organizationConfigurationRequests'

export default {
  name: 'Organizations',

  components: {
    WorkrolesList,
    RightsTable,
  },
  data() {
    return {
      // workrolesToAdd et workrolesToRemove sont utilisés pour stocker les nouveaux workroles en attendant d'avoir les endpoints correspondants
      // Leur utilisation entre en conflit avec le système de pagination au fetch et entraîne quelques dysfonctionnements non bloquants sur l'affichage de la liste
      workrole: {
        id: null,
        // TODO: dynamic lang init
        name: { en: '', fr: '' },
        rights: [],
        rightsToAdd: [],
        rightsToDelete: [],
      },
      workrolesToAdd: [],
      workrolesToRemove: [],
      componentToShow: 'list',
      workroleToShow: null,
    }
  },

  computed: {
    ...mapState('auth', ['selectedOrganization', 'user']),
    ...mapState('workroles', ['workrolesLoading', 'workroles']),
    ...mapState('rights', ['rightsLoading', 'rights']),
  },

  mounted() {
    this.fetchAllRights()
    this.fetchWorkroles()
  },

  methods: {
    ...mapActions('workroles', ['fetchWorkroles']),
    ...mapActions('rights', ['fetchAllRights']),

    updateComponent(workrole) {
      this.componentToShow = this.componentToShow === 'table' ? 'list' : 'table'
      if (this.componentToShow === 'list' && workrole) {
        this.workrolesToAdd.push(workrole)
        // this.postOrganizationConfigurationWorkRole()
      } else if (this.componentToShow === 'table') {
        this.workrole = workrole || null
      }
    },
    onSubmit() {
      let action = this.postOrganizationConfigurationWorkRole
      if (this.workrole.id) action = this.patchOrganizationConfigurationWorkRole
      action().then(() => {
        this.fetchWorkroles(true)
      })
    },
    async postOrganizationConfigurationWorkRole() {
      const payload = {
        ...pick(this.workrole, 'name'),
        rights: this.workrole.rightsToAdd,
      }
      await postOrganizationConfigurationWorkRoleRequest(payload).then(() => {
        this.componentToShow = 'list'
      })
    },

    async patchOrganizationConfigurationWorkRole() {
      const payload = {
        ...pick(this.workrole, 'id', 'name'),
        rights: difference(this.workrole.rights.concat(this.workrole.rightsToAdd), this.workrole.rightsToDelete),
      }
      await patchOrganizationConfigurationWorkRoleRequest(payload)
    },

    deleteOrganizationConfigurationWorkRole(workroleId) {
      deleteOrganizationConfigurationWorkRoleRequest(workroleId).then(() => {
        this.fetchWorkroles(true)
      })
    },
  },
}
</script>
